import { D as DATA_SOURCE_ID, a as DATA_SOURCE_NAME, J as JOB_STATUS } from "./index8.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          staticClass: "pull-data-modal",
          attrs: {
            "title": `Update from ${_vm.sourceName}`,
            "after-close": _vm.afterModalClose,
            "mask-closable": false,
            "closable": !_vm.isLoading
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                key: "cancel",
                attrs: {
                  "disabled": _vm.isLoading
                },
                on: {
                  "click": _vm.onCancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                key: "submit",
                attrs: {
                  "disabled": _vm.source == _vm.DATA_SOURCE_ID.JDE || _vm.isLoading,
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.fetchData);
                  }
                }
              }, [_vm._v("OK")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("div", {
          staticClass: "pull-data-modal__content"
        }, [_c("a-row", [_c("a-col", {
          attrs: {
            "xs": 24
          }
        }, [_c("select-input", {
          attrs: {
            "data-source": _vm.listCountries,
            "value": _vm.formData.selectedCountry,
            "form-item": "",
            "source": "countryCode",
            "source-label": "name",
            "label": "Country",
            "rules": "required",
            "disabled": _vm.isLoading
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("selectedCountry", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "xs": 24
          }
        }, [_c("date-picker", {
          attrs: {
            "value": _vm.formData.fromShipDate,
            "form-item": "",
            "label": "From Ship Date",
            "rules": "required",
            "disabled-date": _vm.disabledDate,
            "disabled": _vm.isLoading
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("fromShipDate", $event);
            }
          }
        })], 1), _vm.jobStatus === _vm.JOB_STATUS.IN_PROGRESS ? [_c("a-col", {
          attrs: {
            "xs": 24
          }
        }, [_c("p", {
          staticClass: "text-center"
        }, [_c("a-spin", [_c("a-icon", {
          staticStyle: {
            "font-size": "24px"
          },
          attrs: {
            "slot": "indicator",
            "type": "loading",
            "spin": ""
          },
          slot: "indicator"
        })], 1), _c("span", {
          staticClass: "d-inline-block ml-2"
        }, [_vm._v("Pulling data from")]), _c("span", {
          staticClass: "d-inline-block ml-1"
        }, [_vm._v(_vm._s(_vm.sourceName))])], 1)])] : _vm._e()], 2)], 1)])];
      }
    }])
  });
};
var staticRenderFns$1 = [];
var PullData_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "PullData",
  inject: ["resourceProps"],
  data() {
    const { source } = this.$route.query;
    const [exoDataProps, countryProps] = this.resourceProps;
    const formData = {
      fromShipDate: this.$moment().startOf("month"),
      selectedCountry: "ALL"
    };
    return {
      DATA_SOURCE_ID,
      DATA_SOURCE_NAME,
      JOB_STATUS,
      apiUrl: apiUrl$1,
      exoDataProps,
      countryProps,
      source,
      formData,
      jobStatus: -1,
      visible: true,
      isLoading: false,
      getJobStatusIntervalId: null
    };
  },
  computed: {
    sourceName() {
      return this.source && DATA_SOURCE_NAME[this.source] ? DATA_SOURCE_NAME[this.source] : "BPCS";
    },
    listCountries() {
      return [
        {
          id: 0,
          name: "All",
          countryCode: "ALL"
        },
        ...this.countryProps.crud.getList()
      ];
    }
  },
  created() {
    this.countryProps.crud.fetchList();
  },
  beforeDestroy() {
    if (this.getJobStatusIntervalId) {
      clearInterval(this.getJobStatusIntervalId);
    }
  },
  methods: {
    refreshData() {
      const pageSize = this.exoDataProps.crud.getPagination().pageSize;
      this.exoDataProps.crud.setPagination({ page: 1, pageSize });
      this.exoDataProps.crud.fetchList();
    },
    collectCoutryCode(selectedCountry) {
      if (selectedCountry.toLowerCase() == "all") {
        const countries = this.countryProps.crud.getList();
        return countries.map((country) => country.countryCode).join();
      }
      return selectedCountry;
    },
    async fetchData() {
      this.isLoading = true;
      let endpoint = this.source === DATA_SOURCE_ID.JDE ? "data-jde" : "data-bpcs";
      const payload = {
        params: {
          shipDate: this.$moment(this.formData.fromShipDate),
          countries: this.collectCoutryCode(this.formData.selectedCountry)
        }
      };
      this.jobStatus = JOB_STATUS.IN_PROGRESS;
      try {
        const { data } = await this.axios.get(`${this.apiUrl}/export-order/pull-data/${endpoint}`, payload);
        if (data && data.taskId) {
          this.getJobStatusIntervalId = setInterval(this.getJobStatus, 5e3, data.taskId);
        }
      } catch (err) {
        this.notifyJobResult({ satus: -1 });
      }
    },
    async getJobStatus(id = "") {
      try {
        const { data } = await this.axios.get(`${this.apiUrl}/export-order/tasks/${id}`);
        if (data && (data.status === JOB_STATUS.SUCCESS || data.status === JOB_STATUS.FAULT)) {
          clearInterval(this.getJobStatusIntervalId);
          this.isLoading = false;
          this.jobStatus = data.status;
          this.notifyJobResult(data);
          if (data.status === JOB_STATUS.SUCCESS) {
            this.visible = false;
            this.refreshData();
          }
        }
      } catch (err) {
        clearInterval(this.getJobStatusIntervalId);
        this.isLoading = false;
        this.jobStatus = JOB_STATUS.FAULT;
        this.notifyJobResult({ satus: -1 });
      }
    },
    notifyJobResult({ status, log = "" }) {
      if (status === JOB_STATUS.SUCCESS) {
        this.$notification.success({ message: "Updated Successfully" });
      } else {
        this.$notification.error({
          message: log || "Something went wrong, please try again"
        });
      }
    },
    disabledDate(current) {
      return current && current > this.$moment().endOf("day");
    },
    storeValue(resourceKey, value) {
      this.formData[resourceKey] = value;
    },
    afterModalClose() {
      this.$router.push(this.exoDataProps.redirectRoute);
    },
    onCancel() {
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "aae3ecfe", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var PullData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "api-url": _vm.apiUrl,
      "name": ["export-order.export-order-data", "export-order.common.countries"],
      "redirect-route": ["/export-orders/export-orders-data"],
      "resource-id-name": ["id", "countryCode"]
    }
  }, [_c("pull-data")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "PullDataIndex",
  components: {
    PullData
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
